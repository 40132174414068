<template>
    <div class="appformMobile">
          <div class="title">
              <img src="../../assets/image/logo.png" style="width:200px;margin-right: 12px;">
          </div>

          <div class="infoStyle">Successful Registration <br>
            Please visit https://my.eae.international/ through a desktop broswer to log in with the email address you just entered. The initial password is "impact".
          </div>

    </div>
  </template>

<script>
import { saveAppForm, addStudentInDatabaseV2, getAccount,getContactInfo } from '../../api/eae'
import '../../assets/common/common.css'

export default {
  data () {
    return {
      
    }
  },
  mounted () {


  },
  methods: {
    
  }
}
</script>

  <style scoped lang="scss">
  .appformMobile{
    padding: 20px;
    box-sizing: border-box;
      width: 100vw;
      font-family: "DDINAlternateRegular";
      overflow-y: scroll;
      background: #fff;
          .title{
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #FF6450;
              font-size: 22px;
              margin-bottom: 50px;
              display: flex;
              align-items: center;
          }
          .title1{
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #FF6450;
              font-size: 22px;
              margin-bottom: 50px;
            //   display: flex;
              align-items: center;
              width: 100%;
              text-align: left;
          }
          .infoStyle{
              width: 100%;
              line-height: 30px;
              margin-bottom: 20px;
              text-align: justify;

            }
          .row{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .item{
                  width: 480px;
                  .label{
                      font-size: 16px;
                      font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
                      font-weight: normal;
                      color: #666666;
                  }
                  .input{
                      width: 100%;
                      display: flex;
                      align-items: center;
                      font-size: 18px;

                      font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
                      font-weight: normal;
                      color: #333333;
                      img{
                          margin-right: 24px;
                          cursor: pointer;
                      }
                  }
              }
          }

      .submit{
          .submitBtn{
              width: 90%;
              height: 50px;
              background: #0E4890;
              border-radius: 25px;
              text-align: center;
              line-height: 50px;
              color:#fff;
              font-size: 20px;
              margin: 0 auto;
              margin-top: 84px;
              font-weight: bold;
              cursor: pointer;
          }
          margin-bottom: 50px;
      }
  }

  </style>
  <style lang="scss">
  .appformMobile{
      .el-input__inner {
          border-radius: 6px;
          border: 2px solid #717171;
          font-size: 18px;
      }
      .el-form-item__label{
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
          font-weight: normal;
          color: #666666;
          line-height: 30px;
          margin-bottom: 6px;
      }
      .customSelect{
        width: 100%;

      }
  }
  
  .mobileConfirm{
    width: 80%;
    .el-message-box__container{
        display: flex;
        .el-message-box__status{
            position: relative;
            flex-shrink: 0;
            transform:none
        }
        .el-message-box__message{
            padding-left: 10px;
        }
    }
  }

  </style>
